import './App.css';

function App() {
  return (
    <div className="app-root">
        <div className="app-logo" />
    </div>
  );
}

export default App;
